@import '../../styles/config.scss';

.button {
  border: $border-width-thin solid;
  border-radius: $border-radius-sm;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .hidden-label {
    @include visually-hidden;
  }

  // Variants
  &.default {
    box-shadow: $drop-shadow-light;

    @include themed {
      border-color: theme($color-neutral-bg-high);
      background-color: theme($color-neutral-bg-lowest);
    }

    .icon {
      @include themed {
        fill: theme($color-neutral-text-low);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-neutral-bg-low);
      }
    }

    &:focus {
      @include outline-border;

      @include themed {
        outline-color: theme($color-primary-brand-base);
      }
    }

    &:disabled {
      @include themed {
        background-color: theme($color-neutral-bg-low);
        border-color: theme($color-neutral-bg-low);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-bg-highest);
        }
      }
    }
  }

  &.primary {
    @include themed {
      background-color: theme($color-primary-brand-base);
      border-color: theme($color-primary-brand-base);
    }

    .icon {
      @include themed {
        fill: theme($color-neutral-bg-lowest);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-primary-brand-high);
        border-color: theme($color-primary-brand-high);
      }
    }

    &:focus {
      @include outline-border;

      @include themed {
        outline-color: theme($color-primary-brand-base);
      }
    }

    &:disabled {
      @include themed {
        background-color: theme($color-neutral-bg-low);
        border-color: theme($color-neutral-bg-low);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-bg-highest);
        }
      }
    }
  }

  &.secondary {
    @include themed {
      background-color: theme($color-secondary-brand-base);
      border-color: theme($color-secondary-brand-base);
    }

    .icon {
      @include themed {
        fill: theme($color-neutral-bg-lowest);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-secondary-brand-high);
        border-color: theme($color-secondary-brand-high);
      }
    }

    &:focus {
      @include outline-border;

      @include themed {
        outline-color: theme($color-secondary-brand-base);
      }
    }

    &:disabled {
      @include themed {
        background-color: theme($color-neutral-bg-low);
        border-color: theme($color-neutral-bg-low);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-bg-highest);
        }
      }
    }
  }

  &.ghost {
    @include themed {
      background-color: transparent;
      border-color: transparent;
    }

    .icon {
      @include themed {
        fill: theme($color-neutral-text-high);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-neutral-bg-low);
      }
    }

    &:focus {
      @include outline-border;
    }

    &:disabled {
      .icon {
        @include themed {
          fill: theme($color-neutral-bg-highest);
        }
      }
    }
  }

  &.ghost-on-brand {
    @include themed {
      background-color: transparent;
      border-color: transparent;
    }

    .icon {
      @include themed {
        fill: theme($color-neutral-bg-lowest);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-neutral-400-opacity-light);
      }
    }

    &:focus {
      outline: $border-width-thick solid;
      outline-offset: $border-width-thick;

      @include themed {
        outline-color: theme($color-neutral-bg-lowest);
      }
    }

    &:disabled {
      @include themed {
        color: theme($color-neutral-text-lowest);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-text-lowest);
        }
      }
    }
  }

  &.highlight-alt {
    @include themed {
      background-color: theme($color-highlight-alt-base);
      color: theme($color-neutral-text-highest);
      border-color: theme($color-highlight-alt-base);
    }

    .icon {
      @include themed {
        fill: theme($color-neutral-text-highest);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-highlight-alt-low);
        border-color: theme($color-highlight-alt-low);
      }
    }

    &:focus {
      outline: $border-width-thick solid;
      outline-offset: $border-width-thick;

      @include themed {
        outline-color: theme($color-primary-brand-base);
      }
    }

    &:disabled {
      @include themed {
        background-color: theme($color-neutral-bg-low);
        color: theme($color-neutral-text-lowest);
        border-color: theme($color-neutral-bg-low);
      }

      .icon {
        @include themed {
          fill: theme($color-neutral-text-lowest);
        }
      }
    }
  }

  // Sizes
  &.large {
    padding: 1.1rem;

    .icon {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
    }
  }

  &.medium {
    padding: 0.9rem;

    .icon {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
    }
  }

  &.small {
    padding: 0.7rem;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
      min-width: 1.6rem;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
