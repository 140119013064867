@import '../../styles/config.scss';

.container {
  .content {
    width: 36rem;
    padding: 0.4rem;
    align-items: flex-start;
    @include themed {
      background-color: theme($color-neutral-text-highest);
    }

    .inner-content {
      display: grid;
      grid-template-columns: 1fr 4rem;
      width: 100%;

      // Only way to access inner div from react-hot-toast library
      > div {
        margin: 0;
        padding: 1rem 0.4rem 1rem 2rem;
      }

      .message-container {
        width: 100%;

        .title-text {
          @include heading-xs;
          @include themed {
            color: theme($color-neutral-bg-lowest);
          }
        }

        .description-text {
          margin-top: 0.8rem;

          @include body-short-md;
          @include themed {
            color: theme($color-neutral-bg-high);
          }
        }
      }

      .icon-button {
        align-self: start;
      }
    }
  }

  .error-content {
    width: 36rem;
    padding: 0.4rem;
    align-items: flex-start;

    @include themed {
      background-color: theme($color-feedback-error-base);
    }

    .inner-content {
      display: grid;
      grid-template-columns: 1fr 4rem;
      width: 100%;

      // Only way to access inner div from react-hot-toast library
      > div {
        margin: 0;
        padding: 1rem 0.4rem 1rem 2rem;
      }

      .message-container {
        width: 100%;

        .title-text {
          @include heading-xs;
          @include themed {
            color: theme($color-neutral-bg-lowest);
          }
        }

        .description-text {
          margin-top: 0.8rem;

          @include body-short-md;
          @include themed {
            color: theme($color-feedback-error-lowest);
          }
        }
      }

      .icon-button {
        align-self: start;
        @include themed {
          background: theme($color-feedback-error-base);
          border-color: theme($color-feedback-error-base);
        }

        &:hover {
          @include themed {
            background: theme($color-feedback-error-high);
            border-color: theme($color-feedback-error-high);
          }
        }
      }
    }
  }
}
